import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { legalCopy } from "../copy"
import { Footer } from "../components/Footer"
import { Helmet } from "react-helmet"
import SEO from '../components/seo'

const LegalPage = props => {
  return (
    <LegalContainer>
      <SEO title={'Legal'} /> 
      <GlobalStyle />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Legal</title>
        <link rel="canonical" href="http://isatrainblocking11th.com/legal" />
      </Helmet>
      <LegalTitleContainer>
        <LegalTitle>Legal</LegalTitle>
      </LegalTitleContainer>
      <LegalTextContainer>
        <LegalText>{legalCopy.p1}</LegalText>
        <LegalText>{legalCopy.p2}</LegalText>
        <LegalText>{legalCopy.p3}</LegalText>
        <LegalText>{legalCopy.p4}</LegalText>
        <LegalTextEndContainer>
          <LegalText>{legalCopy.p5}</LegalText>
        </LegalTextEndContainer>
      </LegalTextContainer>
      <LegalFooter>
        <Footer backgroundColor={"white"} />
      </LegalFooter>
    </LegalContainer>
  )
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #fff;
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
  }
`

const LegalContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(4, auto);
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  align-content: stretch;
  @media screen and (min-width: 760px) {
    grid-template-rows: auto 150px auto auto;
    align-content: flex-start;
  }
`

const LegalTitleContainer = styled.div`
  display: flex;
  height: auto;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
  text-align: left;
  @media screen and (min-width: 760px) {
    margin-left: 30%;
    margin-right: 30%;
  }
`

const LegalTitle = styled.p`
  font-family: Aktiv Grotesk, sans-serif;
  color: #000;
  font-weight: 700;
  font-size: 36px;
  @media screen and (min-width: 760px) {
    font-weight: 700;
    font-size: 48px;
  }
`
const LegalTextContainer = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  text-align: left;
  @media screen and (min-width: 760px) {
    margin-left: 30%;
    margin-right: 30%;
  }
`

const LegalText = styled.p`
  font-family: Aktiv Grotesk, sans-serif;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 16px;
  color: #888;
  line-height: 1.4em;
  @media screen and (min-width: 760px) {
    font-size: 16px;
    line-height: 1.6em;
  }
  @media screen and (max-width: 320px) {
    font-size: 13px;
  }
`

const LegalTextEndContainer = styled.div`
  padding-bottom: 50px;
`

const LegalFooter = styled.div`
  grid-row: 7;
  bottom: 0;
  position: sticky;
  width: 100%;
  height: fit-content;
  justify-items: center;
  align-self: end;
  justify-self: end;
  align-items: end;
  @media screen and (min-width: 760px) {
    grid-row: 1;
    align-self: stretch;
    align-content: center;
  }
`

export default LegalPage
